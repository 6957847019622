import React from "react";

const EmergencyModal = props => {
  return (
    <div id="emergency-modal" data-uk-modal>
      <div
        className="uk-modal-dialog uk-modal-body"
        style={{ background: "white" }}
      >
        <h3 className="uk-modal-title uk-text-uppercase uk-text-center kavi-letter-spacing-1 kavi-text-poppin-orange">
          A Word From Dr. Gore
        </h3>
        <hr />
        <a className="uk-flex uk-flex-center uk-flex-middle"
        href="https://vermilion.sfo2.digitaloceanspaces.com/assets/dr-gore-statement-6-20.pdf"
        target="_blank">
          <button
            className='uk-button uk-button-secondary uk-border-rounded hvr-grow uk-button-large'
          >
          <span className='fas fa-info-circle uk-margin-small-right'/>
            Read our statement
          </button>


        </a>
      </div>
    </div>
  );
};

export default EmergencyModal;
