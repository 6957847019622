import React from 'react'

import OurMissionBackgroundImage from '../../../images/headers/ourmission.jpg'

import Template from './Template'

const pages = {
  school: () => <Template
    headerImage={OurMissionBackgroundImage}
    heroText='School-Based Youth Programs'
    apiURL='https://staging-backend.kavibrooklyn.org/api/programs_school?_format=json'
          />,
  hospital: () => <Template
    headerImage={OurMissionBackgroundImage}
    heroText='Hospital Based Programs'
    apiURL='https://staging-backend.kavibrooklyn.org/api/programs_hospital?_format=json'
            />,
  community: () => <Template
    headerImage={OurMissionBackgroundImage}
    heroText='Community Based Programs'
    apiURL='https://staging-backend.kavibrooklyn.org/api/programs_community?_format=json'
             />
}

export default pages
