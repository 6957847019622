import React from 'react'
import classNames from 'classnames'

import { Link } from 'react-router-dom'

function parseDelimitedHeroText(delimitedText) {
  let splitDelimitedText = delimitedText.split(' ')

  return splitDelimitedText.map( (item, index) => {
    if (/^`.*`(,*)$/.test(item)) {
      return (
        <span key={index} children={item.replace(/`/g, '') + ' '} />
      )
    } else {
      return <React.Fragment key={index} children={item + ' '} />
    }
  })
}

export default (props) => {
  let className = classNames(
    props.className,
    'hero',
  )

  let text = props.heroText
  if (!React.isValidElement(props.heroText)) {
    text = parseDelimitedHeroText(props.heroText)
  }

  let heightViewport = (props.heightViewport ? props.heightViewport : null)

  return (
    <div className={className} data-uk-height-viewport={heightViewport} style={{height: props.height}}>

      <div className='hero-content' style={{marginTop: '80px', paddingTop: '2rem', paddingBottom: '2rem'}}>

        <h1 className='kavi-poppin-orange-accent uk-margin-remove'>{text}</h1>
        {(props.ctaText && props.ctaUrl) &&
          <a href={props.ctaUrl} className='uk-margin-top-small'>
            <button className='uk-button uk-button-primary uk-border-rounded uk-text-small uk-width-2-3 stick-button hvr-buzz-out uk-margin-small-top'>{props.ctaText}</button>
          </a>
        }

      </div>

    </div>
  )
}
