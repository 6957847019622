import React from 'react'
import classNames from 'classnames'

export default (props) => {
  return (
      <div className='uk-width-1-1 uk-padding uk-container kavi-background-accent-blue'>

        <blockquote className='uk-text-center' cite="#">
          <p className="uk-margin-small-bottom uk-text-large kavi-text-white">"{props.text}"</p>

          {props.source &&
            <footer>{props.source}</footer>
          }

          {(props.ctaUrl && props.ctaText) &&
            <a href={props.ctaUrl} target='_blank'>
              <button className="uk-margin uk-button uk-button-primary uk-border-rounded uk-width-1-3@s hvr-buzz-out">{props.ctaText}</button>
            </a>
          }
        </blockquote>
      </div>
  )
}
