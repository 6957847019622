import React from 'react'
import classNames from 'classnames'

import SchoolImage from '../../images/cards/intervention/kavi-at-school.png'
import HospitalImage from '../../images/cards/intervention/hospital-interventionists.png'
import CommunityImage from '../../images/cards/intervention/community.png'

import { Link } from 'react-router-dom'

const InterventionCard = (props) => {

  return (
    <Link className='uk-link-reset' to={`/ourwork/${props.location}`}>
      <div className='uk-card uk-card-default uk-box-shadow-medium uk-box-shadow-hover-large hvr-icon-shrink'
        style={{
          border: '.25px solid rgba(0,0,0,0.175)'
        }}
      >

        <div className="uk-card-media-top">
          <img data-src={props.image} alt='' width='' height='' data-uk-img/>
        </div>

        <div className="uk-card-footer uk-text-center uk-text-uppercase">
          <span className='uk-text-uppercase'>{props.footer}<span className={`uk-margin-small-left ${props.faIcon} hvr-icon`}/></span>
        </div>

      </div>

    </Link>
  )
}

const InterventionCards = [
    <InterventionCard image={SchoolImage} footer='School' faIcon='fas fa-book-reader' location='school' />,
    <InterventionCard image={HospitalImage} footer='Hospital' faIcon='fas fa-hand-holding-heart' location='hospital' />,
    <InterventionCard image={CommunityImage} footer='Community' faIcon='fas fa-briefcase-medical' location='community' />
]

export default (props) => {

  return (
    <div className='uk-section uk-section-muted kavi-gradient-1'>

      <div className='uk-container'>

        <div className='uk-flex uk-flex-column uk-flex-middle'>

          <h1 className='uk-container-title uk-margin-remove'>Interventions</h1>
          <hr className='uk-divider-icon uk-width-2-3' />

        </div>

        <div className='uk-hidden@s' data-uk-slider>
          <ul className='uk-slider-items uk-child-width-1-1 uk-grid uk-flex-middle'>
            {InterventionCards.map((card, index) => <li key={index}><div style={{margin: '2rem', marginTop: null}}>{card}</div></li>)}
          </ul>

          <div className='uk-flex uk-flex-center uk-flex-around uk-flex-row'>
            <a href='#' style={{color: '#666'}} className='fas fa-chevron-left' onClick={(e)=>e.preventDefault()} data-uk-slider-item='previous'/>
            <ul className="uk-slider-nav uk-dotnav"></ul>
            <a href='#' style={{color: '#666'}} className='fas fa-chevron-right' onClick={(e)=>e.preventDefault()} data-uk-slider-item='next'/>
          </div>
        </div>

        <div className='uk-visible@s uk-child-width-1-3' data-uk-grid>
          {InterventionCards.map((card, index) => <div key={index}>{card}</div>)}
        </div>

      </div>

    </div>
  )
}
