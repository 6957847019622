import React from 'react'

import Header from '../../templates/Header'
import Hero from '../../templates/Hero'
import { KaviFooter } from '../../templates/Footer'

import OurWorkCard from '../../templates/OurWorkCard'

import UIkit from 'uikit'

let HeroText = <React.Fragment><span>Work</span> With Us</React.Fragment>

import TheTeamBackgroundImage from '../../../images/headers/ourmission.jpg'

const ExampleCard = {
  title: 'reiciendis voluptatibus maiores',
  summary: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.',
  description: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
  applyText: 'Harum',
  applyUrl: 'https://example.com',
  image: require('../../../images/cards/ourwork/school.jpg')
}

var getClosest = function (elem, selector) {
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if ( elem.matches( selector ) ) return elem;
	}

	return null;
}

export default class Template extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cards: [ExampleCard]
    }
  }

  componentDidMount() {
    UIkit.util.on('#ourwork-accordion', 'show', function (x, y) {
      let liElement = getClosest(x.target, '.ourwork-card')
      liElement.querySelector('.ourwork-card-icon').classList.add('rotate-90deg')

      liElement.querySelector('.readmore').classList.add('readmore-less')
    });

    UIkit.util.on('#ourwork-accordion', 'hide', function (x, y) {
      let liElement = getClosest(x.target, '.ourwork-card')
      liElement.querySelector('.ourwork-card-icon').classList.remove('rotate-90deg')

      liElement.querySelector('.readmore').classList.remove('readmore-less')
    });
  }

  render() {
    return (
      <React.Fragment>
      {/* Header */}
      <Header backgroundImage={TheTeamBackgroundImage} height='uk-height-large'>

        <Hero heroText={HeroText} height='100%'/>
      </Header>

        <div className='uk-section kavi-gradient-1' style={{background: 'rgb(240,240,240)'}}>
          <div className='uk-container'>
            <ul id='ourwork-accordion' data-uk-accordion>
            <li className='ourwork-card'>
              <a className='uk-accordion-title' href='#'>
                <div className='uk-accordion-title-container'>

                  <div className='ourwork-preview'>
                    <div className='ourwork-preview-title'>Program Director</div>

                    <div className='ourwork-preview-content'>
                      <p>
                      KAVI has grown exponentially over the last 5 years. During this incredible season of growth,
                      we have expanded our programs and organizational capacity. We are seeking an energetic,
                      strategic Program Director (part-time) who will be responsible for leadership and day-to-day
                      operations of KAVI’s Community and School Interventions in Brooklyn.
                      </p>
                    </div>
                  </div>

                </div>

                <div className='ourwork-card-footer'>
                  <span>
                    <span className='readmore' />
                    <span className='fas fa-chevron-right ourwork-card-icon uk-margin-small-left' />
                  </span>
                </div>

              </a>

              <div className='uk-accordion-content uk-padding uk-text-small uk-margin-remove-top'>
                  <div>
                  <h3>KAVI's Programs</h3>
                    <p>KAVI works in schools: KAVI runs youth programs to support our young people to fulfill their
                      potential, handle conflict and do better in school. Our programs run through the school year
                      and help central Brooklyn students to stay safe and walk away from violence and access a
                      more supportive school environment that encourages their passions, dreams and future
                      careers. We equip teachers and school staff with training and tools to provide a community of
                      support around young people so they feel safe and valued.</p>
                      <p>KAVI works at Kings County Hospital: KAVI runs a violence intervention program to support
                      youth who are both perpetrators and victims of violence to access the tools they need to be
                      safe, healthy and thrive. Our program helps young people be better able to cope with their
                      trauma, address and de-escalate conflict and become active young leaders.</p>
                      <p>KAVI works in the community: We create community engagement programs that bring family
                      and neighbors together to discuss and act on violence prevention. Our programs help
                      community members to advocate for our youth, understand and practice restorative justice and
                      participate in collective healing.</p>
                    <h3>Essential Job Functions</h3>
                    <ul>
                    <li>Supervise implementation of KAVI’s Program consistent with contractual mandates,
                    standards and procedures set by grant guidelines and deliverables.</li>
                    <li>Create opportunities for participants to maximize their potential through engaging
                    programming, high expectations, and quality services.</li>
                    <li>Ensure program utilization meets contractual obligations.</li>
                    <li>Recruit and screen Peer Advocates, Volunteers, Contractors, Content Experts and other
                    program support staff.</li>
                    <li>Ensure that all staff receive required trainings and booster sessions.</li>
                    <li>Coordinate technical assistance.</li>
                    <li>Conduct assessment and create Service Plans in conjunction with Advocate Mentors,
                    mentees and family members.</li>
                    <li>Provide supervision and coaching to Intervention Coordinators; review all case notes.</li> 
                    <li>Lead staff meetings with Intervention teams.</li>
                    <li>Develop programming schedule.</li>
                    <li>Serve as liaison with schools and collaborating CBO’s.</li>
                    <li>Oversee community initiatives in support of the Community Intervention Coordinator.</li>
                    <li>Ensure proper staff usage of internal and external databases.</li>
                    <li>Monitor and manage program budget.</li>
                    <li>Develop, track and report program milestones, outputs and outcomes and ensure timely
                    reporting.</li>
                    <li>Develop and implement processes that ensure integration of participant feedback in
                    program planning.</li>
                    <li>Participate in community based work groups and committees.</li>
                    <li>Represent KAVI at external meetings.</li>
                    <li>Complete and provide narrative and statistical reports to KAVI’s leadership team.</li>
                    </ul>

                    <h3>Experience and Qualifications</h3>
                    <ul>
                    <li>Bachelor’s degree in public health, social work, youth development.</li>
                    <li>Prior supervisory experience.</li>
                    <li>Experience and comfort working with communities of color and youth.</li>
                    <li>Familiarity with Central Brooklyn community and the challenges for youth living there.</li> 
                    <li>Solid group work skills, familiarity with cognitive-behavioral interventions, and/or evidence-
                    based practice, preferred.</li> 
                    <li>Ability to work from a strengths-based, non-judgmental, growth mindset; this includes
                    trauma informed practices and restorative justice framework.</li> 
                    <li>Ability to work evenings and some weekends.</li>
                    <li>Ability to work proficiently remotely.</li>
                    <li>Proficiency using word processing, spreadsheet and databases.</li>
                    <li>Must have a commitment to work from a strength based and/or youth development
                    perspective.</li>
                    <li>Comprehensive understanding of evaluation and research methods.</li>
                    </ul>

                    <h3>How to Apply</h3>
                    <p>
                    Click to send your resume and cover letter to <a class="uk-link-text" href="mailto:info@kavibrooklyn.org?subject=Program Director">info@kavibrooklyn.org</a> with <em>Program
                    Director</em> in the subject line.</p>
                    <p>KAVI offers a comprehensive employee benefits package for all employees (including part-
                    time).</p>
                    <p>KAVI is an equal opportunity employer and is committed to maintaining a work and learning
                    environment free from discrimination on the basis of race, color, religion, national origin,
                    pregnancy, gender identity, sexual orientation, marital/civil union status, ancestry, place of
                    birth, age, citizenship status, veteran status, political affiliation, genetic information or disability,
                    as defined and required by state and federal laws.</p>
                  </div>


                  <div className='uk-flex uk-flex-right uk-flex-middle uk-margin-small-top'>
                    <a className='uk-button uk-button-default' href='mailto:info@kavibrooklyn.org?subject=Program Director'>Apply now<span class='fas fa-external-link-alt uk-margin-small-left' /></a>
                  </div>

                  </div>
                    </li>
            </ul>
          </div>
        </div>

        {/* Footer */}
        {KaviFooter}
      </React.Fragment>
    )
  }
}
