import React from 'react'
import classNames from 'classnames'

import Hero from '../templates/Hero'
import Header from '../templates/Header'
import { KaviFooter } from '../templates/Footer'

import PlaceholderImage from '../templates/PlaceholderImage'

export default (props) => {
  return (
    <React.Fragment>
      {/* Header */}
      <Header backgroundImage={<PlaceholderImage x='1920' y='1080' text='nullzzzzzzzzzzzz' />} height='uk-height-1-1'>

        <Hero  className='kavi-pattern-5' heroText={'`Page` Not `Found`'} ctaText={'Take Me Home!'} ctaUrl={'/'} heightViewport='offset-top: true;'/>
      </Header>

      {/* Footer */}
      {KaviFooter}

    </React.Fragment>
  )
}
