import React from 'react'
import classNames from 'classnames'

import Hero from '../templates/Hero'
import Header from '../templates/Header'
import Loader from '../templates/Loader';
import { KaviFooter } from '../templates/Footer'

import PlaceholderImage from '../templates/PlaceholderImage'
//import StripeLogo from '../../images/brands/stripe-logo-blue.svg'

let HeroText = <React.Fragment>Take <span>Action</span></React.Fragment>
import OurMissionBackgroundImage from '../../images/headers/ourmission.jpg'

//import DonateModal from '../DonateModal'

import axios from 'axios'

{/* Temporary, to be replaced with data from TAC API. */}
{/* Removed <PlaceholderImage x='1920' y='1080' text='null' /> for now. */}
const inKindTAC = {
  image: null,
  heading: 'In-Kind Contributions',
  body: 'If you have skills or resources that are applicable to our mission, and if you would like to donate something other than money, then KAVI will gratefully accept in-kind contributions.',
  footer: 'E-mail us',
  footer_link: 'mailto:info@kavibrooklyn.org?subject=From%20kavibrooklyn.org%3A%20In-Kind%20Contribution'
}

const volunteerTAC = {
  image: null,
  heading: 'Become a Volunteer',
  body: 'Our success is also largely dependent on great people giving of their time and energy. Volunteers are essential to our work. Join us as a partner in tackling one of the most pressing issues facing young people today.',
  footer: 'Apply to volunteer',
  footer_link: 'https://kavibrooklyn.flipcause.com/secure/cause_pdetails/Nzk1NDA=',
}

const TakeActionCard = (props) => (
  <div className='takeaction-card uk-box-shadow-medium uk-box-shadow-hover-large uk-margin hvr-icon-forward'>

    <div className='uk-flex uk-flex-center uk-flex-middle'>
      {props.image}

      <div className='uk-flex uk-flex-column uk-flex-around uk-padding-small'>
        <div>
          <h3 className='uk-text-uppercase uk-margin-remove-bottom'>{props.heading}</h3>
          <hr />
        </div>

        <p className='uk-margin-remove'>{props.body}</p>
      </div>
    </div>

    {props.footer &&
      <a href={props.footer_link} className='footer uk-link-reset' target="_blank">
        <div className='uk-text-center'>
          {props.footer}<span className='fas fa-chevron-right hvr-icon uk-margin-small-left'/>
        </div>
      </a>
    }

  </div>
)

export default class TakeAction extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      hero: {
        text: 'Loading...',
        cta: {
          text: 'Loading...',
          url: ''
        }
      },
      splash: {
        text: 'Loading...',
        cta: {
          text: 'Loading...',
          url: ''
        }
      }
    }
  }

  componentDidMount() {
    axios.get('https://staging-backend.kavibrooklyn.org/api/take_action?_format=json')
      .then( (res) => {
        let data = res.data[0]

        let banner_text = data.banner_text

        this.setState({
          loading: false,
          hero: {
            background: data.banner_image,
            text: data.banner_text,
            cta: {
              text: data.banner_cta_link_text,
              url: data.banner_cta_link
            }
          },
          splash: {
            text: data.splash_text,
            cta: {
              text: data.splash_cta_text,
              url: data.splash_cta_url
            }
          }
        })

      }, (err) => console.log(err))
  }

  render() {
    return(
      <React.Fragment>
        {/* Header */}
        <Header backgroundImage={this.state.hero.background} height='uk-height-large'>

          { this.state.loading ? <Loader heightLarge={true} /> :

            <Hero heroText={this.state.hero.text} ctaText={this.state.hero.cta.text} ctaUrl={this.state.hero.cta.url} height='100%'/>

          }

        </Header>

        <div className='uk-section kavi-gradient-1' style={{background: 'rgb(240,240,240)'}}>
          <div className='uk-container'>
            <div className='kavi-background-white uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding uk-box-shadow-medium uk-margin-large' style={{border: '.05rem solid rgba(0,0,0,0.2)'}}>
              <span className='kavi-text-black kavi-letter-spacing-5-10 uk-text-small uk-text-center uk-text-uppercase uk-flex uk-flex-center uk-flex-middle'>Make a one-time or monthly contribution</span>

              <hr className='uk-width-expand' style={{borderColor: 'rgba(0,0,0,0.2)'}}/>

              <p className='uk-margin-remove-top uk-text-meta uk-text-center kavi-text-muted-orange'>
                Click below to make a contribution.
              </p>

              <a style={{padding: '5px 0rem 5px 0rem'}} href='#' onClick={ () => open_window('Nzg5NTY=','www.flipcause.com')}
              className='uk-padding-small uk-button uk-button-secondary uk-border-rounded uk-width-2-3 uk-box-shadow-medium uk-position-relative'>
                Donate <span className='uk-margin-small-left fas fa-credit-card' />
              </a>




              <p className='uk-text-meta uk-text-center' style={{margin: '1.3rem 0rem 0rem 0rem', fontSize:'.75rem'}}>
                KAVI is a nonprofit, 501(c)(3) organization. Your donations help us provide crucial education, leadership, and social support programs to youth and young adults in low-income and underserved communities. Please make a tax-deductible donation today - any amount is most appreciated! For more information on how your donations are used or to learn about other ways you can support KAVI programs, please contact development@kavibrooklyn.org.
              </p>

            </div>

            <div className='kavi-background-white uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding uk-box-shadow-medium uk-margin-large' style={{border: '.05rem solid rgba(0,0,0,0.2)'}}>
              <h2 className='uk-text-center uk-text-uppercase kavi-text-poppin-orange kavi-letter-spacing-1'>How can I help?</h2>

              <TakeActionCard {...inKindTAC} /> <TakeActionCard {...volunteerTAC} />
            </div>

            <div className='kavi-sponsors kavi-background-white uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding uk-box-shadow-medium' style={{border: '.05rem solid rgba(0,0,0,0.2)'}}>
              <h2 className='uk-text-center uk-text-uppercase kavi-text-poppin-orange kavi-letter-spacing-1 uk-margin-remove-bottom'>Thank you to all of our Sponsors and Supporters</h2>

              <hr className='uk-width-expand' style={{borderColor: 'rgba(0,0,0,0.2)'}}/>

              <div className='uk-flex uk-flex-center uk-flex-middle uk-padding-small'>
                <div className='uk-flex uk-flex-column uk-flex-middle uk-flex-between'>
                  <img className='uk-margin-small' src={require('../../images/brands/The-Pinkerton-Foundation.png')} />
                  <img className='uk-margin-small' src={require('../../images/brands/gunsdownlifeup.jpg')} />
                  <img className='uk-margin-small' src={require('../../images/brands/sunnyOfficialLogo.jpg')} />
                  <img className='uk-margin-small' src={require('../../images/brands/KCH.png')} />
                  <img className='uk-margin-small' src={require('../../images/brands/trinity_og_logo.jpg')} />
                </div>

                <div className='uk-flex uk-flex-column uk-flex-middle uk-flex-between'>
                  <img className='uk-margin-small' width='200' height='auto' src={require('../../images/brands/spark-youth.png')} />
                  <img className='uk-margin-small' src={require('../../images/brands/HHC.jpg')} />
                  <img className='uk-margin-small' src={require('../../images/brands/wellmet.jpg')} />
                </div>

                <div className='uk-flex uk-flex-column uk-flex-middle uk-flex-between'>
                  <img className='uk-margin-small' src={require('../../images/brands/bcf-logo.png')} />
                  <img className='uk-margin-small' style={{background: '#000'}} src={require('../../images/brands/New-Logo-2016-White.png')} />
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* Footer */}
        {KaviFooter}
      </React.Fragment>
    )
  }
}
