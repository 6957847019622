import React from 'react'

import classNames from 'classnames'

import Hero from '../templates/Hero'
import Header from '../templates/Header'
import Loader from '../templates/Loader';
import BlockquoteCTA from '../templates/BlockquoteCTA'
import { KaviFooter } from '../templates/Footer'

import Intervention from './Intervention'
import Featured from './Featured'

import UIkit from 'uikit'

import EmergencyModal from '../EmergencyModal'

{/*import HeaderBackgroundImage from '../../images/headers/home.jpg'*/}

import axios from 'axios'

export default class Home extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      hero: {
        text: 'Loading...',
        cta: {
          text: 'Loading...',
          url: ''
        }
      },
      splash: {
        text: 'Loading...',
        cta: {
          text: 'Loading...',
          url: ''
        }
      }
    }
  }

  componentDidMount() {
    this._isMounted = true
    axios.get('https://staging-backend.kavibrooklyn.org/api/home?_format=json')
      .then( (res) => {
        let data = res.data[0]

        let banner_text = data.banner_text
        if (this._isMounted) {
        this.setState({
          loading: false,
          hero: {
            background: data.banner_image,
            text: data.banner_text,
            cta: {
              text: data.banner_cta_link_text,
              url: data.banner_cta_link
            }
          },
          splash: {
            text: data.splash_text,
            cta: {
              text: data.splash_cta_text,
              url: data.splash_cta_url
            }
          }
        })
      }

      UIkit.modal.alert('<h3 class="uk-modal-title uk-text-uppercase uk-text-center kavi-letter-spacing-1 kavi-text-poppin-orange">A Word from Dr. Gore</h3><hr /><a class="uk-flex uk-flex-center uk-flex-middle" href="https://vermilion.sfo2.digitaloceanspaces.com/assets/dr-gore-statement-6-20.pdf" target="_blank"> <button class="uk-button uk-button-secondary uk-border-rounded hvr-grow uk-button-large"><span class="fas fa-info-circle uk-margin-small-right"></span>Read our statement</button> </a>');
      }, (err) => console.log(err));

  }

  render() {
    return (
      <React.Fragment>
        {/* Header */}
        <Header backgroundImage={this.state.hero.background} height='uk-height-1-1'>

          {
            this.state.loading ? <Loader fullHeight={true} /> : <Hero heroText={this.state.hero.text} ctaText={this.state.hero.cta.text} ctaUrl={this.state.hero.cta.url} heightViewport='offset-top: true;'/>
          }
        </Header>
        <div class="kavi-gradient-1 kavi-gradient-top">
        <div class="uk-text-justify uk-width-2-3@m uk-margin-auto">
            <h1 className='uk-container-title uk-margin-remove uk-padding-large uk-padding-remove-bottom'>Our Mission</h1>
            <hr className='uk-divider-icon uk-width-2-3 uk-margin-auto' />
            <p class="uk-text-medium uk-text-bold uk-margin-auto uk-padding-large uk-padding-remove-top">Kings Against Violence Initiative, Inc. (KAVI) is a nonprofit, 501(c)(3) youth-serving organization with a mission to prevent and eliminate interpersonal violence from the lives of young people through advocacy, peer leadership, community mobilization, and social justice.
            <br/><br/>KAVI operates youth violence interventions programs in Central Brooklyn.  Our programs take place in schools and hospital-based settings.  We also work with a broad range of partners to do community strengthening and healing programs.</p>
          </div>
        </div>

        {/* Intervention*/}
        <Intervention />

        {/* Blockquote CTA */}
        <BlockquoteCTA text={this.state.splash.text} ctaText={this.state.splash.cta.text} ctaUrl={this.state.splash.cta.url} />

        {/* Featured */}
        <Featured />

        {/* Footer */}
        {KaviFooter}

        {/* Emergency Messaging Modal */}
        <EmergencyModal />

      </React.Fragment>
    )
  }
}
